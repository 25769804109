import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/templates/MDXDocs.js";
import { Divider } from 'fannypack';
import ColouredText from '../../../components/worksafe-vic/ColouredText';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h2 {...{
      "id": "tyro-machines",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#tyro-machines",
        "aria-label": "tyro machines permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Tyro machines`}</h2>
    <h3 {...{
      "id": "machine-types",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#machine-types",
        "aria-label": "machine types permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Machine types`}</h3>
    <p>{`Tyro currently offers two terminal variants:`}</p>
    <ul>
      <li parentName="ul">{`A desktop (Yomani) version called `}<strong parentName="li">{`CounterTop`}</strong></li>
      <li parentName="ul">{`A mobile (Yoximo) version called `}<strong parentName="li">{`Mobile`}</strong></li>
    </ul>
    <p>{`The CounterTop version can run EFTPOS transactions and HealthPoint transactions. The device requires a physical ethernet or 3G connection.`}</p>
    <p>{`The Mobile version can run EFTPOS transactions and is not currently certified for other transaction types and cannot process HealthPoint claims. The Mobile device can connect via either WiFi or a built-in mobile data connection serviced by Optel or Telstra.`}</p>
    <p>{`A simulator is available for testing purposes which will emulate a CounterTop device.`}</p>
    <h3 {...{
      "id": "setup-and-configuration",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#setup-and-configuration",
        "aria-label": "setup and configuration permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Setup and configuration`}</h3>
    <p>{`Each physical machine will have a unique terminal identifier `}<strong parentName="p">{`(TID)`}</strong>{`. If multiple machines are used in a single physical location, the TID is used to differentiate one from another.`}</p>
    <p>{`The Tyro Health SDK requires each machine to be setup in the Medipass portal. Setup requires physical access to a machine, entry of TID and merchant details. This setup must be done once for each terminal and once for each merchant configured on a terminal. Details on setup can be found here: `}<a parentName="p" {...{
        "href": "https://help.medipass.com.au/en/articles/5938215-enable-tyro-healthpoint-in-medipass"
      }}>{`https://help.medipass.com.au/en/articles/5938215-enable-tyro-healthpoint-in-medipass`}</a></p>
    <p>{`Once configured, connected terminals and merchants can be accessed by a single business API key.`}</p>
    <h3 {...{
      "id": "transaction-sdk-setup",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#transaction-sdk-setup",
        "aria-label": "transaction sdk setup permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Transaction SDK Setup`}</h3>
    <p>{`Setup of the Transaction SDK, authenticating users and identifying partner systems is covered in: `}<a parentName="p" {...{
        "href": "https://docs.medipass.io/sdk/transaction/getting-started/setup/"
      }}>{`https://docs.medipass.io/sdk/transaction/getting-started/setup/`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      